/* global ajax_object */
// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
// import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/google-map';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

// Import gsap
import { gsap, ScrollTrigger, ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}
/**
 * Add class to header
 */
function headerAddClass() {
  let scrolled = $(window).scrollTop();
  if (scrolled >= 60) {
    $('header').addClass('scrolled');
  } else {
    $('header').removeClass('scrolled');
  }
}

/**
 * Set sections top padding on home page
 */
let setHeight = function () {
  let top = $('header').outerHeight();
  $('.home-section, .navbar-wrap, .js-padding-top').css({
    'padding-top': top + 'px',
  });
};

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.navbar-nav > li > a').click(function () {
    if ($(this).parent().hasClass('show') || $(window).width() > 992) {
      location.href = this.href;
    }
  });
  $('.js-wine-selector').on('change', function () {
    $(
      '.wine-selector__item, .wine-selector__image, .wine-video, .wine-quote__inner'
    ).hide();
    $('.' + $(this).val()).show();
  });

  headerAddClass();

  $('button.navbar-toggler').on('click', function () {
    $('#header-navbar').slideToggle();
    $('body').toggleClass('overflow-hidden menu-open');
    setHeight();
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox]');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  jarallaxElement();
  jarallax(document.querySelectorAll('.jarallax'), {
    speed: 0.4,
    disableParallax: /iPhone|iPod|Android/,
  });
  jarallax(document.querySelectorAll('.jarallax-keep-img'), {
    speed: 0.4,
    keepImg: true,
    disableParallax: /iPhone|iPod|Android/,
  });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  resizeVideo();

  const sections = document.querySelectorAll('.js-home-anim');

  // this scrolling object just allows us to conveniently call scrolling.enable(), scrolling.disable(), and check if scrolling.enabled is true.
  // some browsers (like iOS Safari) handle scrolling on a separate thread and can cause things to get out of sync (jitter/jumpy), so when we're animating the scroll position, force an update of GSAP tweens when there's a scroll event in order to maintain synchronization)
  const scrolling = {
    enabled: true,
    events: 'scroll,wheel,touchmove,pointermove'.split(','),
    prevent: function (e) {
      if (e.cancelable) e.preventDefault();
    },
    disable() {
      if (scrolling.enabled) {
        scrolling.enabled = false;
        window.addEventListener('scroll', gsap.ticker.tick, {
          passive: true,
        });
        scrolling.events.forEach((e, i) =>
          (i ? document : window).addEventListener(e, scrolling.prevent, {
            passive: false,
          })
        );
      }
    },
    enable() {
      if (!scrolling.enabled) {
        scrolling.enabled = true;
        window.removeEventListener('scroll', gsap.ticker.tick);
        scrolling.events.forEach((e, i) =>
          (i ? document : window).removeEventListener(e, scrolling.prevent)
        );
      }
    },
  };

  function goToSection(section, hideLogo) {
    if (scrolling.enabled) {
      // skip if a scroll tween is in progress
      if (hideLogo) {
        $('.logo').hide();
      } else {
        $('.logo').show();
      }
      scrolling.disable();
      gsap.to(window, {
        scrollTo: { y: section, autoKill: false },
        onComplete: scrolling.enable,
        ease: 'slow(0.7, 0.7, false)',
        // ease: "power4.inOut",
        duration: 1,
        stagger: 0.1,
      });
    }
  }

  if ($('body').hasClass('home')) {
    if ($(window).width() <= 1024) {
      ScrollTrigger.normalizeScroll(true);
    }
    sections.forEach((section, i) => {
      let footerHeight = window.innerHeight - $('.footer').outerHeight() + 2;
      let end = 'bottom top+=1';
      let hideLogo;
      if (sections.length - 2 == i) {
        end = 'bottom top+=' + footerHeight;
        hideLogo = false;
      } else if (sections.length - 1 == i) {
        hideLogo = true;
      }
      ScrollTrigger.create({
        trigger: section,
        markers: false,
        start: 'top bottom-=1',
        end: end,
        onEnter: () => goToSection(section, hideLogo),
        onEnterBack: () => goToSection(section, hideLogo),
      });
    });
    ScrollTrigger.refresh();
  }
  $('#load-gallery').on('click', function (e) {
    // when our trigger is clicked

    e.preventDefault(); // prevent any default behaviors from this anchor

    let button = $(this), // trigger
      // imageEntry = $('.js-gallery-item'), //images
      data = {
        // JS data object with variables defined in PHP Ajax handler
        action: 'load_gallery', // Ajax function defined in handler that contains data we're pulling in
        post_id: button.attr('data-id'), // inline post ID variable
        chunks: button.attr('data-chunks'), // inline post ID variable
        count: button.attr('data-gallery-count'), // inline post ID variable
        nonce: ajax_object.nonce, // inline nonce variable
      };

    $.post({
      // can also use $.ajax and declare 'post' type, but this is a shortcut in newer jQuery versions
      url: ajax_object.ajax_url, // inline WordPress Ajax URL variable defined in handler
      data: data, // object defined above
      dataType: 'json',
      beforeSend: function () {
        // runs before ajax request is sent

        // add loading state. you can do any number of things here, get creative!
        button.addClass('loading');
        // button.find('.button-text').text('Loading...');
      },
      success: function (response) {
        // runs right after Ajax call succeeds
        if (response) {
          // only run if the data object exists
          //remove loading state
          button.removeClass('loading');
          // button.find('.button-text').text('See More');
          button.attr('data-gallery-count', response.chunk);
          if (response.chunk >= button.attr('data-chunks')) {
            button.remove();
          }
          // imageEntry.remove(); // clear existing slides
          $('.js-gallery').append(response.html); // append data (after the nav element, in our case)
        }
      },
    });
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
  setHeight();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  setHeight();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  if ($('body').hasClass('home')) {
    setHeight();
  }
  if ($(window).scrollTop() < 30) {
    setHeight();
  }
  headerAddClass();
});
